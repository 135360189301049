import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/database';
import 'firebase/firestore';
import { GeoFire } from 'geofire';

import { getConfig } from './constants';

const firebaseConfig = {
  apiKey: 'AIzaSyABozWrJ2w1pPDCi_LKQFIBOb0orQCHcHE',
  authDomain: 'dlvr-automate.firebaseapp.com',
  databaseURL: 'https://dlvr-automate.firebaseio.com',
  projectId: 'dlvr-automate',
  storageBucket: 'dlvr-automate.appspot.com',
  messagingSenderId: '378468976840',
  appId: '1:378468976840:web:99670fac91940cae3b7dfd',
};

export const admin = firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export const storageRef = storage.ref();

export const db = firebase.database();

const leadsLocationStrapiRef = db.ref(
  getConfig().FIREBASE_LOCATION_REF || 'leads-location-data',
);

export const getDispatcherLocationGeoFireInstance = (): GeoFire =>
  new GeoFire(leadsLocationStrapiRef);
